import React from 'react';
import {Container} from "../utils/commonStyledComponents";

const IFrameComponent = ({section}) =>
    (
        <Container>
            <iframe
                style={{width: '100%', height: '1000px'}}
                src={section.content}
            />
        </Container>
    );

export default IFrameComponent;
