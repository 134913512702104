import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {
    StylesProvider,
    ThemeProvider as MuiProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Login from './screens/Login';
import Logout from './screens/Logout';
import Register from './screens/Register';
import ForgotPassword from './screens/ForgotPassword';
import Dashboard from './screens/Dashboard';
import Account from './screens/Account';
import Payments from './screens/Payments';
import Documents from './screens/Documents';
import Practices from './screens/Practices';
import Vouchers from './screens/Vouchers';

import Layout from './components/Layout';
import {UserProvider, useUser} from './utils/UserSession';
import {fetchUser, fetchDynamicSections} from './utils/api';
//import {bootIntercom} from './utils/intercom';

import theme from './utils/theme';
import './App.css';
import AppHeader from './components/AppHeader';
import IFrameComponent from "./components/IFrameComponent";
import EditPractice from "./screens/EditPractice";
import UpsellingPractices from "./screens/UpsellingPractices";
import UpsellPractice from "./screens/UpsellPractice";
import ForgotUsername from "./screens/ForgotUsername";
import {AlertProvider} from "./utils/AlertProvider";

function App() {
    const [loading, setLoading] = useState(true);
    const [dynamicSections, setDynamicSections] = useState([]);

    const {token, setUser, setToken} = useUser();

    useEffect(async () => {
        try {
            const response = await fetchDynamicSections()
            if (response) {
                setDynamicSections(response);
            }
        } catch (e) {
            console.error(e);
            setToken('');
            setLoading(false);
        }
        if (token) {

            fetchUser()
                .then(async user => {
                    setUser(user);
                    //bootIntercom(user);
                })
                .catch(() => setToken(''))
                .then(() => setLoading(false));
        } else {
            // bootIntercom();
            setLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return <Layout/>;
    }

    return (
        <Layout>
            <Switch>
                <Route path="/login" component={Login}/>
                <Route path="/logout" component={Logout}/>
                <Route path="/register" component={Register}/>
                <Route path="/password-recovery" component={ForgotPassword} dynamicSections={dynamicSections}/>
                <Route path="/username-recovery" component={ForgotUsername} dynamicSections={dynamicSections}/>
                <PrivatedRoute path="/dashboard" component={Dashboard} dynamicSections={dynamicSections}/>
                <PrivatedRoute path="/account" component={Account} dynamicSections={dynamicSections}/>
                <PrivatedRoute path="/payments" component={Payments} dynamicSections={dynamicSections}/>
                <PrivatedRoute path="/documents" component={Documents} dynamicSections={dynamicSections}/>
                <PrivatedRoute path="/practices" component={Practices} dynamicSections={dynamicSections}/>
                <PrivatedRoute path="/upselling" component={UpsellingPractices} dynamicSections={dynamicSections}/>
                <PrivatedRoute path="/vouchers" component={Vouchers} dynamicSections={dynamicSections}/>
                <PrivatedRoute path="/edit-practice/:id" component={EditPractice} dynamicSections={dynamicSections}/>
                <PrivatedRoute path="/upsell-practice/:id" component={UpsellPractice}
                               dynamicSections={dynamicSections}/>
                {dynamicSections?.map((sec) => <PrivatedRoute path={`/${sec.toPath}`} currentSection={sec}
                                                              component={() => <IFrameComponent section={sec}/>}
                                                              dynamicSections={dynamicSections}/>)}

                <Redirect to="/dashboard"/>
            </Switch>
        </Layout>
    );
}

function PrivatedRoute(props) {
    const {user} = useUser();

    if (!user.username) {
        return <Redirect to="/login"/>;
    }

    return (
        <>
            <AppHeader route={props.location.pathname} section={props.currentSection}
                       dynamicSections={props.dynamicSections}/>
            <Route {...props} />
        </>
    );
}

const withProviders = Comp => props => (
    <>
        <CssBaseline/>
        <ThemeProvider theme={theme}>
            <MuiProvider theme={theme}>
                <StylesProvider injectFirst>
                    <UserProvider>
                        <AlertProvider>
                            <Router>
                                <Comp {...props} />
                            </Router>
                        </AlertProvider>
                    </UserProvider>
                </StylesProvider>
            </MuiProvider>
        </ThemeProvider>
    </>
);

export default withProviders(App);
