import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';

import AppBar from './AppBar';
import PointsBox from './PointsBox';
import IconLink from './IconLink';
import {useUser} from '../utils/UserSession';

const AppHeader = ({route, section, dynamicSections = []}) => {
    const {user} = useUser();
    const {title, description} = getScreenText(route, user, section);

    return (
        <>
            <AppBar/>
            <Container>
                <TextContainer>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                    <TextSeparator/>
                </TextContainer>

                <OptionsContainer>
                    <PointsBox/>

                    <LinksContainer>
                        <IconLink
                            to="/account"
                            text="Informazioni account"
                            icon="Member"
                            selected={route === '/account'}
                        />
                        <IconLink
                            to="/practices"
                            text="Prenotazioni"
                            icon="Practices"
                            selected={route === '/practices'}
                        />
                        <IconLink
                            to="/payments"
                            text="Pagamenti/Fatture"
                            icon="Payments"
                            selected={route === '/payments'}
                        />
                        <IconLink
                            to="/documents"
                            text="Contratti/Documenti"
                            icon="Documents"
                            selected={route === '/documents'}
                        />
                        <IconLink
                            to="/vouchers"
                            text="Voucher"
                            icon="Voucher"
                            selected={route === '/vouchers'}
                        />
                        {dynamicSections.map((section) => {
                            return <IconLink
                                key={section.id}
                                to={`/${section.toPath}`}
                                text={section.label}
                                icon={section.icon}
                                selected={route === `/${section.toPath}`}
                            />;
                        })
                        }
                        <IconLink
                            to="/upselling"
                            text="Personalizza la tua esperienza"
                            icon="Upselling"
                            selected={route === '/upselling'}
                        />
                    </LinksContainer>
                </OptionsContainer>
            </Container>
        </>
    );
};

const getScreenText = (route, user, section) => {
    if (section !== undefined && `/${section.toPath}` === route) {
        return {
            title: section.title,
            description: section.subtitle,
        };
    }

    if (route === '/account') {
        return {
            title: 'Informazioni Account',
            description:
                'Qui potrai vedere ed editare tutte le informazioni relative al tuo account Giocamondo!',
        };
    }

    if (route === '/practices') {
        return {
            title: 'Pratiche',
            //description: 'In questa sezione potrai visionare le tue pratiche attive',
            //description: 'In questa sezione potrai visionare le tue pratiche attive. Ti ricordiamo che i riferimenti che vedrai sulle date ed eventuali servizi non più disponibili saranno aggiornati con i nuovi riferimento per l’anno 2021. NB. le date di partenza dei soggiorni saranno definite entro dicembre 2020.',
            description: 'In questa sezione puoi visionare tutte le tue prenotazioni. Cliccando sopra la riga di ciascuna pratica sarà possibile vederne i dettagli e in alcuni momenti effettuare autonomamente modifiche e/o richieste.',
        };
    }

    if (route === '/payments') {
        return {
            title: 'Pagamenti e Fatture',
            description:
                'In questa sezione potrai visionare la situazione contabile delle tue prenotazioni',
        };
    }

    if (route === '/documents') {
        return {
            title: 'Contratti e Documenti',
            description:
                'In questa sezione potrai visionare i tuoi contratti e procedere al caricamento dei documenti qualora fosse necessario',
        };
    }

    if (route === '/vouchers') {
        return {
            title: 'Voucher',
            description:
                'In questa sezione potrai visionare i tuoi voucher',
        };
    }


    if (route === '/upselling') {
        return {
            title: 'Personalizza la tua esperienza',
            description:
                'In questa sezione potrai personalizzare la tua esperienza scegliendo i servizi più adatti per vivere al meglio il tuo soggiorno. Segui le indicazioni richieste dal sistema per arrivare a confermare la tua modifica di upgrade della prenotazione. La tua esperienza potrà migliorare anche con eventuali nuovi servizi extra messi a disposizione solo per te (es. servizi assicurativi e/o coperture, escursioni, certificazioni, ecc…). NB. si possono modificare solo le prenotazioni nello stato opzionato e/o confermato',
        };
    }

    return {
        title: `Ciao ${user.firstName.toUpperCase()}!`,
        description:
            "Benvenuto nella tua area riservata. Qui potrai vedere tutte le informazioni relative alle tue attività e tenere sott'occhio il tuo stato di avanzamento all'interno della nostra Membership.",
    };
};

AppHeader.propTypes = {
    route: PropTypes.string.isRequired,
};

export default AppHeader;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 32px;
  padding: 32px;
  padding-bottom: 0;
  box-sizing: border-box;
  border-bottom: 0.5px solid ${props => props.theme.palette.grey[200]};
  background-color: ${props => props.theme.palette.grey[100]};
  position: relative;
`;

const TextContainer = styled.div`
  flex: 1;

  @media (min-width: 767px) {
    margin-right: 32px;
  }
`;

const Description = styled(Typography).attrs({variant: 'body1'})`
  min-width: 300px;
  max-width: 600px;
`;

const Title = styled(Typography).attrs({variant: 'h3'})`
  margin-bottom: 16px;
`;

const TextSeparator = styled.div`
  width: 60px;
  height: 0.5px;
  border-radius: 2px;
  margin-top: 8px;
  background-color: ${props => props.theme.palette.grey[400]};
`;

const OptionsContainer = styled.div`
  display: flex;
  position: inherit;
  bottom: -16px;
`;

const LinksContainer = styled.div`
  width: 182px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.common.white};

  @media screen and (max-width: 767px) {
    border: 1px solid #eee;
  }
`;
