import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';
import {
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Box,
  Typography,
  Button, Link,
} from '@material-ui/core';
import {
  Spinner,
} from '../../utils/commonStyledComponents';
import PaymentButton from './PaymentButton';

const PaymentDetails = ({practice, updatePractices, displayAlert}) => {
  const {
    remainingAmount,
    privatoPayedAmount,
    practicePrice,
    iNPSPayedAmount,
    practicePayments,
    invoiceData,
    loadingInvoiceData,
    invoiceDataError,
    fiscalDocs,
    isFinanceable,
    financingUrl,
  } = practice;

  return (
    <Container className="responsivecolumn">
      <RecapContainer>
        <Title>RIEPILOGO PAGAMENTI</Title>
        {renderTable(practicePayments)}
        <Title>RIEPILOGO FATTURE</Title>
        {!!loadingInvoiceData ? (
          <Row>
            <Title>Caricando dati fatture <Spinner /></Title>
          </Row>
        ) : !!invoiceDataError ? (
          <Row>
            <Title>Errore nel caricamento dei documenti fiscali</Title>
          </Row>
        ) : (renderTableInvoice(fiscalDocs))}
      </RecapContainer>

      <BalanceContainer>
        <Row>
          <Title>SALDO PAGAMENTI:</Title>
          <BadgeBox bgcolor={remainingAmount >= 0 ? '#f39c12' : '#00a65a'}>
            {remainingAmount} €
          </BadgeBox>
        </Row>
        {!!privatoPayedAmount && (
          <Row>
            <Label>Pagato dal privato:</Label>
            <span>
              {privatoPayedAmount} € di {(practicePrice / 100).toFixed(2)} €
            </span>
          </Row>
        )}
        {!!iNPSPayedAmount && (
          <Row>
            <Label>Pagato da INPS:</Label>
            <span>{iNPSPayedAmount} €</span>
          </Row>
        )}

        <PaymentButton
          practice={practice}
          onComplete={updatePractices}
          displayAlert={displayAlert}
          isPracticePayable={practice.readableStatus.trim().toLowerCase() === "opzionato" || practice.readableStatus.trim().toLowerCase() === "confermato"}
        />

        {!!isFinanceable && (
            <Link href={financingUrl} underline="none" target="_blank">
              <FinancingButton>
                RICHIEDI FINANZIAMENTO
              </FinancingButton>
            </Link>
        )}
      </BalanceContainer>

      {/**
       * New section for Invoice prospect
       */}
       <InvoiceContainer>
        {!!loadingInvoiceData ? (
          <Row>
            <Title>Caricando dati fatture <Spinner /></Title>
          </Row>
        ) : !!invoiceDataError ? (
          <Row>
            <Title>Errore nel caricamento dei documenti fiscali</Title>
          </Row>
        ) : (
          <>
            <Row>
              <Title>SALDO FATTURE:</Title>
              <BadgeBox bgcolor={invoiceData.invoicesDueAmount >= 0 ? '#f39c12' : '#00a65a'}>
                {invoiceData.invoicesDueAmount} €
              </BadgeBox>
            </Row>

            <Row>
              <Label>Importo Fatturato:</Label>
              <span>
                {invoiceData.invoicesEmittedAmount} €
              </span>
            </Row>

            <Row>
              <Label>Totale da Fatturare:</Label>
              <span>{invoiceData.invoicesTotalAmount} €</span>
            </Row>
          </>
        )}

      </InvoiceContainer>

    </Container>
  );
};

const renderTable = payments => {
  if (!payments.length) {
    return <i>Nessun pagamento disponibile per questa prenotazione</i>;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Causale</TableCell>
            <TableCell>Origine</TableCell>
            <TableCell>Importo</TableCell>
            <TableCell>Canale</TableCell>
            <TableCell>Stato pagamento</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {payments.map((payment, index) => (
            <TableRow key={index}>
              <TableCell>
                {formatDate(new Date(payment.date), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell>{payment.causal}</TableCell>
              <TableCell>{payment.source}</TableCell>
              <TableCell>€ {payment.amount.toFixed(2)}</TableCell>
              <TableCell>{payment.channel}</TableCell>
              <TableCell>{payment.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const renderTableInvoice = fiscalDocs => {
  if (!fiscalDocs.success) {
    return <i>Al momento non è possibile recupare le fatture per questa prenotazione</i>;
  }
  if (!fiscalDocs.rows.length) {
    return <i>Nessuna fattura disponibile per questa prenotazione</i>;
  }

  return (
    <TableContainer>
      <Table className="tab_invoices">
        <TableHead>
          <TableRow>
            <TableCell>Doc n.</TableCell>
            <TableCell>Data</TableCell>
            {/* TIPOLOGIA RICHIEDE API DI DECODIFICA
            <TableCell>Tipologia</TableCell> */}
            <TableCell>Importo</TableCell>
            <TableCell>Documento</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {fiscalDocs.rows.map((doc, index) => (
            <TableRow key={index}>
              <TableCell>{doc.invoicenumber}</TableCell>
              <TableCell>{doc.invoicedate}</TableCell>
              {/* TIPOLOGIA RICHIEDE API DI DECODIFICA
              <TableCell></TableCell>*/}
              <TableCell>€ {doc.totalamount}</TableCell>
              <TableCell align="right">
                {doc.attachmenturl ? (
                  <a
                    className="btn btn-xs btn-secondary"
                    href={doc.attachmenturl}
                    target="_blank"
                    rel="noreferrer noopener">
                    Apri PDF
                  </a>
                ) : (
                  <span>
                    PDF non generato!
                  </span>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PaymentDetails.propTypes = {
  practice: PropTypes.shape({
    remainingAmount: PropTypes.number,
    privatoPayedAmount: PropTypes.number,
    practicePrice: PropTypes.string,
    iNPSPayedAmount: PropTypes.number,
    loadingInvoiceData: PropTypes.bool,
    invoiceDataError: PropTypes.object,
    invoiceData: PropTypes.shape({
      invoicesDueAmount: PropTypes.number,
      invoicesEmittedAmount: PropTypes.number,
      invoicesTotalAmount: PropTypes.number
    }),
    practicePayments: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        causal: PropTypes.string,
        source: PropTypes.string,
        amount: PropTypes.number,
        channel: PropTypes.string,
        status: PropTypes.string,
      })
    ),
  }),
  updatePractices: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
};

export default PaymentDetails;


const Container = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 32px 16px;
  margin-top: 24px;
  background-color: #f7f7f7;
`;

const RecapContainer = styled.div`
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const BadgeBox = styled(Box)`
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  padding: 4px 8px;
  color: white;
  min-width: 100px;
  text-align: center;
  margin: 0 8px;
`;

const BalanceContainer = styled.div`
  padding: 0 24px;
  margin-left: 24px;
  border-left: 2px solid white;
`;

const Table = styled(MuiTable)`
  background-color: white;
  border-radius: 8px;
`;

const TableRow = styled(MuiTableRow)`
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell).attrs({align: 'center'})``;

const Title = styled(Typography).attrs({
  component: 'h2',
  variant: 'h6',
})`
  display: block;
  margin: 24px 0;
`;

const Label = styled(Typography).attrs({
  component: 'h4',
  variant: 'body1',
})`
  font-weight: 600;
  margin-right: 4px;
`;

const InvoiceContainer = styled.div`
  padding: 0 24px;
  margin-left: 24px;
  border-left: 2px solid white;
`;

const FinancingButton = styled(Button).attrs({
  color: 'primary',
  variant: 'contained',
})`
  display: block;
  margin: 16px 0;
`;
