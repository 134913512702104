import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Typography} from '@material-ui/core';
import {
  PersonOutline as Member,
  CollectionsBookmark as Practices,
  CreditCard as Payments,
  Assignment as Documents,
} from '@material-ui/icons';

const Icons = {
  Member,
  Practices,
  Payments,
  Documents,
};

const CardLink = ({icon, text, background, to}) => {
  const Icon = Icons[icon];

  return (
    <Container background={background} to={to}>
      <Text>{text}</Text>
      <IconContainer>
        <Icon fontSize="inherit" color="inherit" />
      </IconContainer>
    </Container>
  );
};

CardLink.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default CardLink;

const Container = styled(Link)`
  margin: 8px 8px 0;
  padding: 16px;
  min-width: 170px;
  height: 210px;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;

  display: flex;
  flex: 1;
  align-items: center;
  position: relative;

  text-decoration: none;
  overflow: hidden;
  box-shadow: none;
  background-color: ${props => props.background};
`;

const Text = styled(Typography).attrs({
  variant: 'h2',
  color: 'secondary',
})``;

const IconContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: -75px;
  font-size: 120px;
  color: ${props => props.theme.palette.common.white};
`;
