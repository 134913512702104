import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';

import Button from '../components/Button';

export default function Layout({children}) {
  return (
    <Root>
      <Children>{children}</Children>

      <section>
        
      </section>

      <section id="next-app" style={{background:"#fff", padding: "2% 2% 0"}}>
        <blockquote style={{borderLeft: "solid 10px #f3b118", paddingLeft: "15px"}}>
          <p><em><span style={{fontFamily: "georgia, palatino, serif"}}>Giocamondo Study sta investendo nella digitalizzazione dei processi aziendali per ottimizzare l’efficacia, coinvolgere i consumatori e rimanere competitiva. L’obiettivo è passare da processi efficaci a processi efficienti, attraverso una revisione e l’implementazione di soluzioni digitali standardizzate. Il programma d’investimento punta a ampliare la capacità produttiva, modernizzare gli asset tecnologici e integrare il processo produttivo attraverso la trasformazione digitale.</span></em></p></blockquote>
            <table 
            style={{borderCollapse: "collapse", width: "100%",
            borderCollapse: "collapse",
            width: "90%",
            textAlign: "center",
            margin: "1% 5%"}}>
              <tbody>
                <tr>
                  <td><img alt="APP Giocamondo Study: l'organizzazione della Vacanze Studio 2022 diventa anche digitale! - Giocamondo Study-NEXT-APPENNINO-ai-Google-Drive" class="alignnone image-next" src="https://www.giocamondostudy.it/wp-content/uploads/2022/03/NEXT-APPENNINO-ai-Google-Drive.png" width="457" height="100" srcset="https://www.giocamondostudy.it/wp-content/uploads/2022/03/NEXT-APPENNINO-ai-Google-Drive.png 661w, https://www.giocamondostudy.it/wp-content/uploads/2022/03/NEXT-APPENNINO-ai-Google-Drive-300x65.png 300w, https://www.giocamondostudy.it/wp-content/uploads/2022/03/NEXT-APPENNINO-ai-Google-Drive-350x76.png 350w" sizes="(max-width: 457px) 100vw, 457px" /></td>
                  <td><img alt="APP Giocamondo Study: l'organizzazione della Vacanze Studio 2022 diventa anche digitale! - Giocamondo Study-logo_ComSisma2016_DEF-ai-Google-Drive" class="alignnone image-next" src="https://www.giocamondostudy.it/wp-content/uploads/2022/03/logo_ComSisma2016_DEF-ai-Google-Drive.png" width="457" height="87" srcset="https://www.giocamondostudy.it/wp-content/uploads/2022/03/logo_ComSisma2016_DEF-ai-Google-Drive.png 675w, https://www.giocamondostudy.it/wp-content/uploads/2022/03/logo_ComSisma2016_DEF-ai-Google-Drive-300x57.png 300w, https://www.giocamondostudy.it/wp-content/uploads/2022/03/logo_ComSisma2016_DEF-ai-Google-Drive-350x66.png 350w" sizes="(max-width: 457px) 100vw, 457px" /></td>
                  <td><img alt="APP Giocamondo Study: l'organizzazione della Vacanze Studio 2022 diventa anche digitale! - Giocamondo Study-images" class="alignnone image-next size-full" src="https://www.giocamondostudy.it/wp-content/uploads/2022/03/images.jpg" width="457" height="110" srcset="https://www.giocamondostudy.it/wp-content/uploads/2022/03/images.jpg 457w, https://www.giocamondostudy.it/wp-content/uploads/2022/03/images-300x72.jpg 300w, https://www.giocamondostudy.it/wp-content/uploads/2022/03/images-350x84.jpg 350w" sizes="(max-width: 457px) 100vw, 457px" /></td>
                </tr>
              </tbody>
          </table>

      </section>
      <FooterContainer>
        <FooterText>
          Per assistenza 
          {/*<br /> da Lun a Ven: 8:30 - 17:30*/}
        </FooterText>
        <ContactInfoText>
          0736.343440 - 0736.336339 <br />
          club@giocamondo.it
        </ContactInfoText>

        <RegulationsButton
          text={
            <>
              Maggiori informazioni e regolamento GiocamondoClub{' '}
              <ArrowChar>&nbsp;&rarr;</ArrowChar>
            </>
          }
          //href="https://docs.google.com/document/d/1PbyulExMkTyyGX-kco7EyukLIGI0mqw_wMgT0w953ns/edit"
          href="http://giocamondo.it/club"
          rel="noopener noreferrer"
          target="_blank"
        />

        <PolicyContainer>
          <Typography>
            <a
              href="https://www.iubenda.com/privacy-policy/883329"
              className="iubenda-white no-brand iubenda-embed"
              title="Privacy Policy "
            >
              Privacy Policy
            </a>

            <a
              href="https://www.iubenda.com/privacy-policy/883329/cookie-policy"
              className="iubenda-white no-brand iubenda-embed"
              title="Cookie Policy "
            >
              Cookie Policy
            </a>
            <br/>
            <a href="#" class="iubenda-cs-preferences-link">Aggiorna i consensi</a>
          </Typography>
        </PolicyContainer>
      </FooterContainer>
    </Root>
  );
}

Layout.propTypes = {
  children: PropTypes.element,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
`;

const Children = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding: 16px;
  background-color: ${props => props.theme.palette.grey[100]};
`;

const PolicyContainer = styled.footer`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const RegulationsButton = styled(Button).attrs({
  variant: 'contained',
  color: 'primary',
  size: 'small',
})`
  display: block;
  margin: 16px 0;
  color: ${props => props.theme.palette.common.grey};
  box-shadow: none;

  height: inherit;
  font-size: 12px;
  background-color: #fff;
  border: solid 2px #d5d5d5;
`;

const FooterText = styled(Typography).attrs({
  variant: 'h6',
  align: 'center',
})``;

const ContactInfoText = styled(FooterText).attrs({
  color: 'primary',
})`
  text-decoration: underline;
`;

const ArrowChar = styled.span`
  font-size: 24;
  padding-bottom: 3px;
`;
