import React, {useState, useCallback} from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {AppBar as MuiBar, Avatar, Paper, Typography} from '@material-ui/core';

import {useUser} from '../utils/UserSession';
import {getInitial} from '../utils/formatString';

const AppBar = () => {
  const {user} = useUser();
  const [showLogout, setShowLogout] = useState(false);

  const toggleLogout = useCallback(() => {
    setShowLogout(!showLogout);
  }, [showLogout]);

  return (
    <Container>
      <LinkBox to="/dashboard">
        <Logo />
      </LinkBox>

      <Box onMouseEnter={toggleLogout} onMouseLeave={toggleLogout}>
        <Text>Ciao {user.firstName.toUpperCase()}</Text>

        <StyledAvatar>
          {getInitial(user.firstName) + getInitial(user.lastName)}
        </StyledAvatar>

        {showLogout && (
          <LogoutContainer>
            <LinkBox to="/logout">Logout</LinkBox>
          </LogoutContainer>
        )}
      </Box>
    </Container>
  );
};

export default AppBar;

const Container = styled(MuiBar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 8px 32px;
  box-shadow: none;
  color: ${props => props.theme.palette.common.white};
  z-index: 1;
`;

const Logo = styled.img.attrs({
  id: 'giocamondo-logo',
  alt: 'Giocamondo Logo',
  src: require('../assets/giocamondo-logo.png'),
})`
  height: 75px;
  margin-left: -10px;
  background-color: #fff;
  padding: 1px 3px;
  border-radius: 5px;

  @media screen and (max-width: 767px) {
    height: 40px;
  }
`;

const boxStyle = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
`;

const Box = styled.div`
  ${boxStyle};
  position: relative;
`;

const LinkBox = styled(Link)`
  ${boxStyle};
`;

const Text = styled(Typography).attrs({
  variant: 'h3',
})``;

const StyledAvatar = styled(Avatar)`
  font-size: 16px;
  font-weight: 500;
  margin-left: 12px;
  background-color: ${props => props.theme.palette.avatar};
`;

const LogoutContainer = styled(Paper)`
  position: absolute;
  right: 0;
  bottom: -28px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  z-index: 3;
`;
